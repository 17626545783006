<template>
  <div id="main_wrapper">

    <Titlebar></Titlebar>

    <MainImage></MainImage>

    <div class="message_wrapper">
      <h2>絵本「うちゅうをすすめ！<br class="sp">えんぴつロケット」への<br>ご応募ありがとうございました。</h2>
      <p>この絵本は「しまうまプリント」の<br>サービスを利用しておりますので<br>「しまうまプリント」からのお届けとなります。</p>
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import Titlebar from '../src/components/Titlebar';
import MainImage from '../src/components/MainImage';
import Footer from '../src/components/Footer';

export default {
  components: { Titlebar, MainImage, Footer },
}
</script>

<style scoped>
.message_wrapper {
  height: 43vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
h2 {
  margin-bottom: 30px;
}

@media screen and (max-width: 960px) {
  .message_wrapper {
    width: 95%;
    margin: 0 auto;
  }
  h2 {
    font-size: 20px;
  }
}



</style>
