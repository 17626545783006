import Vue from 'vue'
import VueAnalytics from 'vue-analytics';
import App from './App.vue'
import './plugins/firebase'
import router from '../router'
import GAuth from 'vue-google-oauth2'
import store from './store'

//window.YubinBango = require('yubinbango-core')

Vue.config.productionTip = false
Vue.use(GAuth, {
    clientId: '864170881930-o9gmq771hl5vebbi3s5cv3u741m0qro2.apps.googleusercontent.com', scope: 'email profile openid', prompt: 'consent', fetch_basic_profile: false
})
Vue.use(VueAnalytics, {
  id: 'UA-29690955-4',
  router
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

