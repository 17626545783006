<template>
  <div id="main_wrapper">

    <!-- header -->
    <Titlebar></Titlebar>

    <!-- main_image -->
    <MainImageBook :isPresentCampaign="isPresentCampaign"></MainImageBook>

    <!-- title -->
    <h1>絵本<br>「うちゅうをすすめ！えんぴつロケット」</h1>
    <div class="title_wrapper">
      <p>モノイズのゲームアプリ「宇宙はっけん隊」が絵本になりました。<br class="pc">BOOTHのmonoisストアにてお買い求めいただけます。<br>
<strong v-if="isPresentCampaign">数量限定で無料プレゼント中！</strong></p>
      <ul class="main_menu">
        <li><a href="#story">ストーリー</a></li>
        <li><a href="#images">イメージ（全ページ）</a></li>
        <li><a href="#review">みんなの声／レビュー</a></li>
        <li><a href="#store">ストア（購入はこちら）</a></li>
        <li v-if="isPresentCampaign"><a href="#gift">無料プレゼント</a></li>
      </ul>

    </div>
    <h2 id="story">ストーリー</h2>
    <div class="content_wrapper">
      <p>『月はどうして僕についてくるの？』そんな疑問を持った主人公が、宇宙を探検して発見したものとは…？<br>
<br>
右も左も前も後ろもわからない宇宙を、暗中模索でぐるぐるぐうぉ〜んと進むロケット。<br>
それは、まるで人生そのもの。<br>
人生には、いい時もあれば、悪い時もあり、今まで頑張ってきたことは無駄だったのかなと落ち込むこともあります。<br>
<br>
でも、子ども達には、どんな時も自分を信じて、困難だって楽しむ力を持って生きていってほしい。<br>
この絵本には、そんな想いを込めました。<br>
<br>
「宇宙はっけん隊」のデザイナーとプランナーであるクリエイターママ2人が、長い時間をかけて「子どもに伝えたいこと」というテーマで語り合い、生まれたお話です。
      </p>
    </div>
    <h2 id="images">イメージ（全ページ）</h2>
    <div class="content_wrapper">
      <ul class="list_images">
        <li><img src="../src/assets/ehon_images/01_02.jpg" /></li>
        <li><img src="../src/assets/ehon_images/03_04.jpg" /></li>
        <li><img src="../src/assets/ehon_images/05_06.jpg" /></li>
        <li><img src="../src/assets/ehon_images/07_08.jpg" /></li>
        <template v-if="showAllImage">
        <li><img src="../src/assets/ehon_images/09_10.jpg" /></li>
        <li><img src="../src/assets/ehon_images/11_12.jpg" /></li>
        <li><img src="../src/assets/ehon_images/13_14.jpg" /></li>
        <li><img src="../src/assets/ehon_images/15_16.jpg" /></li>
        <li><img src="../src/assets/ehon_images/17_18.jpg" /></li>
        <li><img src="../src/assets/ehon_images/19_20.jpg" /></li>
        <li><img src="../src/assets/ehon_images/21_22.jpg" /></li>
        <li><img src="../src/assets/ehon_images/23_24.jpg" /></li>
        <li><img src="../src/assets/ehon_images/25_26.jpg" /></li>
        <li><img src="../src/assets/ehon_images/27_28.jpg" /></li>
        <li><img src="../src/assets/ehon_images/29_30.jpg" /></li>
        <li><img src="../src/assets/ehon_images/31_32.jpg" /></li>
        <li><img src="../src/assets/ehon_images/33.jpg" /></li>
        </template>
      </ul>
      <button v-if="!showAllImage" class="more" @click="onClickAllImageButton">もっとみる</button>
      <button v-if="showAllImage" class="more" @click="onClickAllImageButton">閉じる</button>
    </div>
    <h2 id="review">みんなの声／レビュー</h2>
    <div class="content_wrapper">
      <p>アンケートにご協力いただいた方に、大変ご好評をいただいております！ </p>
      <div class="rating">
        <p>満足度</p>
        <p>4.3</p>
        <p>5段階評価中</p>
        <p><span class="star5_rating" data-rate="4.5"></span></p>
        <p>57件の評価</p>
      </div>
      <template v-if="!showAllReview">
      <ul v-for="review in defaultReviews" v-bind:key="review.id" class="list_reviews">
        <li>{{review}}</li>
      </ul>
      </template>
      <template v-if="showAllReview">
      <ul v-for="review in reviews" v-bind:key="review.id" class="list_reviews">
        <li>{{review}}</li>
      </ul>
      </template>
      <button v-if="!showAllReview" class="more" @click="onClickAllReviewButton">もっとみる</button>
      <button v-if="showAllReview" class="more" @click="onClickAllReviewButton">閉じる</button>
    </div>
    <h2 id="store">ストア（購入はこちら）</h2>
    <div class="content_wrapper">
      <ul class="list_price">
        <li>
          <div>
            <p><img src="../src/assets/product_a.jpg"></p>
            <div class="desc">
              <p class="desc_main">お手ごろタイプ</p>
              <p class="desc_sub">15cm(34ページ)</p>
              <p class="desc_text">まずは、気軽に読んでみたい!という方向けに一番安価 なタイプをご用意しました。</p>
            </div>
          </div>
        </li>
        <li>
          <div>
            <p><img src="../src/assets/product_b.jpg"></p>
            <div class="desc">
              <p class="desc_main">中綴じタイプ</p>
              <p class="desc_sub">18cm(34ページ)</p>
              <p class="desc_text">本文の紙が一番厚いタイプなので、小さなお子様にもオススメ。サイズも大きいので読みきかせにも最適です。</p>
            </div>
          </div>
        </li>
        <li>
          <div>
            <p><img src="../src/assets/product_c.jpg"></p>
            <div class="desc">
              <p class="desc_main">ハードカバータイプ</p>
              <p class="desc_sub">15cm(34ページ)</p>
              <p class="desc_text">サイズが小さく、表紙がハードカバーになっているので、おでかけにもオススメ。高級感があるのでプレゼントに もぴったりです。</p>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="price">
              <tamplate v-if="isPresentCampaign">
              <p class="price_main"><span class="nobuy">&yen;800</span></p>
              <p class="price_desc" v-if="isPresentCampaign">プレゼント中！</p>
             </tamplate>
              <tamplate v-else>
              <p class="price_main">&yen;800</p>
             </tamplate>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="price">
              <p class="price_main">&yen;1,800</p>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="price">
              <p class="price_main">&yen;2,400</p>
            </div>
          </div>
        </li>      </ul>
      <button class="important" @click="onClickStoreButton">BOOTH monoisストアへ</button>
    </div>
    <template v-if="isPresentCampaign">
    <h2 id="gift">無料プレゼント</h2>
    <div class="content_wrapper last">
      <p>期間限定で、お手ごろタイプを先着100名様に無料でお届けいたします。感想アンケートにご協力いただければ幸いです。<br>
皆さまからのご応募、お待ちしております。</p>
      <ul class="caption">
        <li>募集期間：2021/1/22〜1/31 定員に達し次第終了となります。</li>
        <li>期間完了後、しまうまプリントからのお届けとなります。</li>
        <li>一家族につき、一冊までとさせていただきます。</li>
        <li>発送は国内のみとさせていただきます。</li>
        <li>ご入力いただいた情報は、発送とアンケートのお願い（Eメール）のみに利用致します。</li>
      </ul>
      <button class="important" @click="onClickFormButton">プレゼント応募フォーム</button>
    </div>
    </template>

    <!-- footer -->
    <Footer></Footer>

  </div>
</template>

<script>
import Titlebar from '../src/components/Titlebar';
import MainImageBook from '../src/components/MainImageBook';
import Footer from '../src/components/Footer';


export default {
  components: { Titlebar, MainImageBook, Footer },
  data() {
    return {
      showAllImage : false,
      showAllReview : false,
      isPresentCampaign : false,
      reviews : [
        `とても楽しんでいました！
お菓子や新幹線ロケット、消防車ロケットに反応するし、宇宙人のネーミングも良くて子供たちと笑いながら読みました。
我が家ではうんこりあんが大人気です！`,
        `面白かったーと言って音読してくれました。`,
        `ワクワクでニコニコしていました。`,
        `私はおばあちゃんで、双子の孫に読ませたくて応募しました。星やロケット、新幹線も出てきて、絵から興味を持ち楽しんでくれたようです。破れないように読むのが大変でした。すぐ取り合いになるので。ゲームも好きなので、絵本を楽しんでからゲームも誘ってみます。`,
        `宇宙のお話だったので、興味を持っていました。ぐるぐるぐぉ〜んも楽しくて、親子で何度も言い合いました！`,
        `まだ内容は理解出来ていないかもしれませんが、面白かったと言ってました。`,
        `宇宙人が面白くて笑っていました`,
        `アプリですでに遊んでいたのもあり、とても楽しんで何度も読んでます！`,
        `かわいいイラストに心惹かれたようでした。
内容もとても素敵で、学びの機会にもなりました。`,
        `ぐるぐるぐぉーんのフレーズが気に入っています
乗り物好きなので、新幹線が出てくるところで、親しみを持ったようでした
食いしん坊なので、ビスケットやキャンディーのフレーズにも興味を示していました`,
        `絵が可愛い、宇宙人のうんこで笑った、面白い、と言っていました。`,
        `宇宙が好きなので、いろいろなロケットや宇宙人が出てくるところが面白かったと言っていました。絵もすてきなので、こどもが自分も絵本を描いてみたいと言っています。`,
        `絵が可愛くて、子供でも読める文字数と吸い込まれる絵で楽しく読めました。`,
        `えんぴつロケット作ってみようか！
っと言ってました。`,
        `書店で販売されるようでしたらぜひ友人にプレゼントしたいです。`,
        `母の想いが詰まった絵本で、共感しかなく心が温かくなりました。とても素敵な絵本ですね。`,
        `素敵な絵本でした。絵や文章は7歳の子供にとてもわかりやすかったです。そして、もう少し大きくなったらもう一度読んであげたい。お友達や学校で色んな事があった時に、明日はきっともっと楽しい！ってこの本を通して伝えてあげられたらいいなと思います。
親としては、最後の、おしまい(そしてはじまり、、、)がグッときました！
素敵な絵本をありがとうございました！`,
        `まだ0歳なので、内容はまったくわかりません。でも絵が気になるのか時々手を伸ばしていました。特にうちゅうじんのページは握ってしまい離すのが大変でした。`,
        `新幹線が出てきたり、お菓子がでてきたり
宇宙探検で出会うものが好きなものばかりでとても喜んでいました。`,
        `始めて読んだときはじっくり聞き入り、その後も繰り返し『読んで』と持ってきてくれます。
女の子２人なのですが、キャンディーやクッキー、宇宙人のページが特に気に入ったようでお話を読み終わったあとも何度も見直していました。`,
        `とても楽しんでいました！
お菓子や新幹線ロケット、消防車ロケットに反応するし、宇宙人のネーミングも良くて子供たちと笑いながら読みました。
我が家ではうんこりあんが大人気です！`,
        `大人にも伝わる深い内容だと思いました。`,
        `素敵な絵本をありがとうございました！かわいくて楽しく読めるのに、ぐっとくるメッセージもあり長く読み続けたい大切な１冊になりました。`,



      ]
    }
  },
  methods: {
    onClickAllImageButton() {
      this.showAllImage = !this.showAllImage;
    },
        onClickAllReviewButton() {
      this.showAllReview = !this.showAllReview;
    },
    onClickStoreButton() {
      location.href = 'https://monois.booth.pm/';

    },
    onClickFormButton() {
      this.$router.push('/form')
    }
  },
  computed: {
    defaultReviews() {
      return this.reviews.slice(0,4);
    }

  },
  created() {
  },
  watch: {
  }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;700&display=swap');

#main_wrapper {
  background: #fff;
}
.main_image img {
  width: 100%;
}

h1,
h2,
.title_wrapper,
.content_wrapper{
  font-family: 'M PLUS Rounded 1c', sans-serif;
  margin: 0 16px;
  font-size: 12px;
}

.title_wrapper strong{
  color: #C73A33;
  font-weight: normal;
}
ul,li{
  list-style-type: none;
}
ul.main_menu{
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
ul.main_menu li{
  width: 49%;
  height: 34px;
  border: 1px solid #cbcbcb;
  border-radius: 7px;
  line-height: 34px;
  margin-bottom: 10px;
  background: #fff;
}
ul.main_menu li a{
  text-decoration: none;
  color: #393939;
  font-size: 10px;
  display: block;
}
.content_wrapper{
  text-align: left;
}
.content_wrapper.last{
  margin-bottom: 60px;
}
ul.list_images{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
ul.list_images li{
  width: 49%;
  margin-bottom: 5px;
  background: #fff;
}
ul.list_images li img{
  width: 100%;
}
ul.list_images li:nth-child(17) img{
  width: 50%;
}

h1 {
  margin: 0 auto 30px;
  letter-spacing: 0px;
  padding-top: 15px;
  font-size: 18px;
}
h2 {
  margin: 30px 0 20px 0;
  padding: 30px 16px 0px 16px;
  text-align: left;
  font-size: 18px;
  border-top: 1px solid #CBCBCB;
}
button.more{
  margin: 10px 0;
  display: block;
  width: 100%;
  height: 34px;
  border: 1px solid #cbcbcb;
  border-radius: 7px;
  line-height: 34px;
  font-size: 10px;
  color: #696969;
}

div.rating{
  /*background: #f0f0f0;*/
  width: 100%;
  height: 100px;
  position:relative;
}
div.rating p:nth-child(1){
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  top: 10px;
  left: 20px;
}
div.rating p:nth-child(2){
  font-weight: bold;
  font-size: 42px;
  position: absolute;
  top: 20px;
  left: 10px;
}
div.rating p:nth-child(3){
  font-size: 12px;
  position: absolute;
  top: 70px;
  left: 10px;
  color: #696969;
}
div.rating p:nth-child(4){
  position: absolute;
  top: 22px;
  left: 110px;
  color: #CBCBCB;
  font-size: 40px;
}
div.rating p:nth-child(5){
  position: absolute;
  top: 73px;
  left: 230px;
  color: #696969;
  font-size: 12px;
}

.star5_rating{
    position: relative;
    z-index: 0;
    display: inline-block;
    white-space: nowrap;
}

.star5_rating:before, .star5_rating:after{
    content: '★★★★★';
}

.star5_rating:after{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    color: #EE8834; /* イエローカラー 自由に設定化 */
}

.star5_rating[data-rate="5"]:after{ width: 100%; } /* 星5 */
.star5_rating[data-rate="4.5"]:after{ width: 90%; } /* 星4.5 */
.star5_rating[data-rate="4"]:after{ width: 80%; } /* 星4 */
.star5_rating[data-rate="3.5"]:after{ width: 70%; } /* 星3.5 */
.star5_rating[data-rate="3"]:after{ width: 60%; } /* 星3 */
.star5_rating[data-rate="2.5"]:after{ width: 50%; } /* 星2.5 */
.star5_rating[data-rate="2"]:after{ width: 40%; } /* 星2 */
.star5_rating[data-rate="1.5"]:after{ width: 30%; } /* 星1.5 */
.star5_rating[data-rate="1"]:after{ width: 20%; } /* 星1 */
.star5_rating[data-rate="0.5"]:after{ width: 10%; } /* 星0.5 */
.star5_rating[data-rate="0"]:after{ width: 0%; } /* 星0 */

ul.list_reviews li {
  white-space: pre-wrap;
  word-wrap:break-word;
  background: #f0f0f0;
  margin: 7px 0;
  padding: 7px 16px;

}

ul.list_price{
  margin-top: 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
ul.list_price li{
  padding: 3px 0;
  width: calc( (100% - 16px * 2) / 3);
}
ul.list_price li > div{
  /*display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
   */
}
ul.list_price li > div p img{
  width: 100%;
}
ul.list_price li > div div.desc{
  padding-top: 4px;
}

ul.list_price li > div div.desc p.desc_main{
  font-weight: bold;
}
ul.list_price li > div div.desc p.desc_sub{
  color: #999999;
}
ul.list_price li > div div.desc p.desc_text{
  margin-top: 6px;
}
ul.list_price li > div div.price{
  text-align: right;
}
ul.list_price li > div div.price p.price_main{
  font-size: 18px;
}
ul.list_price li > div div.price p.price_main span.nobuy{
  text-decoration: line-through;
}
ul.list_price li > div div.price p.price_desc{
  font-size: 13px;
  color: #C73A33;
}
button.important{
  margin: 20px 0;
  display: block;
  width: 100%;
  height: 40px;
  background: #C73A33;
  border-radius: 20px;
  line-height: 40px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
}

ul.caption {
  background: #F0F0F0;
  margin: 22px 0;
  padding: 10px;
}
ul.caption li{
  list-style-type: disc;
  list-style-position: inside;
}

@media screen and (min-width: 830px) {
  h1,
  h2,
  .title_wrapper,
  .content_wrapper{
    width: 830px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
  }
  h1 {
    font-size: 27px;
  }
  h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 18px;
  }

  ul.caption li{
    font-size: 12px;
  }
  button.more {
    font-size: 12px;
  }
  button.important {
    font-size: 14px;
  }
}

</style>
