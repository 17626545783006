<template>
  <div class="signin">
    <h2>Signin with Google</h2>
    <button @click="signInWithGoogleAuth">Signin</button>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'SigninG',
  data: function () {
    return {
    }
  },
  methods: {
    signInWithGoogleAuth: function () {
      const provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithPopup(provider).then(result => {
        // GoogleプロパイダのOAuthトークンを取得します。
        //const token = result.credential.accessToken
        // ログインしたユーザーの情報を取得します。
        const user = result.user
          alert('Success!' + user)
      }).catch(function(err) {
        console.error(err)
          alert(err.message)
        // エラー処理
      })
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.signin {
  margin-top: 20px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center
}
input {
  margin: 10px 0;
  padding: 10px;
}
</style>