<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  font-family: 'Montserrat', sans-serif;
  background-image: url("../public/bg_grid.png");
  background-color: #fff;
  background-repeat: repeat;
  background-position: center top;
}
input,
select,
button {
  outline: none;
}
.footer_line {
  background-image: url(/public/footer_wave.png);
  background-repeat: repeat-x;
  background-position: bottom;
}
.footer_line {
  content: '';
  display: block;
  width: 100%;
  height: 50px;
  margin: 0 auto;
  background-color: transparent;
  background-image: url(/public/footer_fig1.svg);
  background-repeat: no-repeat;
  background-size: content;
  background-position: 95% 50%;
}
footer {
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding-top: 30px;
  padding-bottom: 36px;
  text-align: center;
  background: #73BBEC;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .pc {
    display: none;
  }
}
@media screen and (min-width: 961px) {
  .sp {
    display: none;
  }
}

</style>
