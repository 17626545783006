import Vue from 'vue'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)

const firebaseApp = firebase.initializeApp({

	//Release

	apiKey: "AIzaSyDjxJCl4icbYlP4uOdt7oJEhdTTJ3dkJhY",
	authDomain: "monois-apps-spaceexplorer.firebaseapp.com",
	databaseURL: "https://monois-apps-spaceexplorer.firebaseio.com",
	projectId: "monois-apps-spaceexplorer",
	storageBucket: "monois-apps-spaceexplorer.appspot.com",
	messagingSenderId: "600594261337",
	appId: "1:600594261337:web:4e86c42397ee4cbc975848",
	measurementId: "G-H4BPW2M8XQ",

	//Develop
/*
    apiKey: "AIzaSyB2SHAYN01JfIla78nZIqcsimttoRafOmg",
    authDomain: "fluttertestvotewithfirestore.firebaseapp.com",
    databaseURL: "https://fluttertestvotewithfirestore.firebaseio.com",
    projectId: "fluttertestvotewithfirestore",
    storageBucket: "fluttertestvotewithfirestore.appspot.com",
    messagingSenderId: "798301341191",
    appId: "1:798301341191:web:03f58e2c633367bcf7cca2",
    measurementId: "G-GV1BL7XVTR",
    */
});

export const db = firebaseApp.firestore();