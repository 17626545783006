<template>
    <div class="main_image">
      <img src="../../src/assets/header.jpg" class="pc2" alt="メイン画像">
      <img v-if="isPresentCampaign" src="../../src/assets/monois_book1.jpg" class="sp2" alt="メイン画像">
      <img v-if="!isPresentCampaign" src="../../src/assets/monois_book2.jpg" class="sp2" alt="メイン画像">
    </div>
</template>

<script>
export default {
  props: ['isPresentCampaign'],
}
</script>
<style scoped>
img {
  width: 100%;
}

@media screen and (max-width: 830px) {
  .pc2 {
    display: none;
  }
}
@media screen and (min-width: 831px) {
  .sp2 {
    display: none;
  }
}

</style>