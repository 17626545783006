<template>
  <div id="main_wrapper">

    <!-- header -->
    <Titlebar></Titlebar>

    <!-- main_image -->
    <MainImage></MainImage>

    <!-- title -->
    <div class="title_wrapper" v-show="!showConfirm">
      <h1>絵本「うちゅうをすすめ！えんぴつロケット」<br>お届け先情報の入力</h1>
      <h2>絵本をお届けする配送先を<br class="sp">ご入力ください。</h2>
      <p>この絵本は「しまうまプリント」の<br class="sp">サービスを利用しておりますので<br>「しまうまプリント」からのお届けとなります。</p>
    </div>

    <!-- form -->
    <div id="form" v-show="!showConfirm">
      <form class="form_wrapper" @submit.prevent="onClickFormButton">
        <input type="hidden" v-model="userData.title">

        <!-- name -->
        <div class="form_items">
          <p class="form_item">お名前<span class="required_tag">※必須</span></p>
          <div class="input_wrapper">
            <input
              type="text"
              v-model="userData.sei"
              placeholder="姓"
              required
            >
            <input
              type="text"
              v-model="userData.mei"
              placeholder="名"
              required
            >
          </div>
        </div>

        <!-- kana -->
        <div class="form_items">
          <p class="form_item">フリガナ<span class="required_tag">※必須</span></p>
          <div class="input_wrapper">
            <input
              type="text"
              v-model="userData.seiKana"
              placeholder="セイ"
              required
            >
            <input
              type="text"
              v-model="userData.meiKana"
              placeholder="メイ"
              required
            >
          </div>
        </div>

        <!-- birthDay -->
        <div id="birth" class="form_items">
          <p class="form_item">生年月日</p>
          <div class="birth_select">
            <select
              class="year_select"
              v-model="userData.birthYear"
            >
              <option v-for="year in yearList" :key="year.id" :value="year">{{ year }}</option>
            </select>
            <p class="birth_text">
              年
            </p>
            <select
              class="month_select"
              v-model="userData.birthMonth"
            >
              <option v-for="month in monthList" :key="month.id" :value="month">{{ month }}</option>
            </select>
            <p class="birth_text">
              月
            </p>
            <select
              class="day_select"
              v-model="userData.birthDay"
            >
              <option v-for="day in dayList" :key="day.id" :value="day">{{ day }}</option>
            </select>
            <p class="birth_text">
              日
            </p>
          </div>
        </div>

        <!-- gender -->
        <div class="form_items">
          <p class="form_item">性別</p>
          <div class="input_wrapper gender_wrapper">
            <label
              class="gender_label"
              for="men"
              :class="[(userData.gender == '男') ? 'select_men' : '' ]"
            >
              男
            </label>
            <input
              id="men"
              type="radio"
              v-model="userData.gender"
              name="gender"
              value="男"
            >
            <label
              class="gender_label"
              for="women"
              :class="[(userData.gender == '女') ? 'select_men' : '' ]"
            >
              女
            </label>
            <input
              id="women"
              type="radio"
              v-model="userData.gender"
              name="gender"
              value="女"
            >
          </div>
        </div>

        <!-- tel -->
        <div class="form_items">
          <p class="form_item">電話番号</p>
          <div class="input_wrapper tel_wrapper">
            <div class="tel_items">
              <input
                type="text"
                v-model="userData.tel00"
              >ー
              <input
                type="text"
                v-model="userData.tel01"
              >ー
              <input
                type="text"
                v-model="userData.tel02"
              >
            </div>
            <p class="error_message" v-show="telError">
              電話番号が不正です
            </p>
          </div>
        </div>

        <!-- mail address -->
        <div class="form_items">
          <p class="form_item">メールアドレス<span class="required_tag">※必須</span></p>
          <div class="input_wrapper mail_wrapper">
            <input
              type="text"
              v-model="userData.mail"
              required
            >
            <p class="error_message" v-show="mailError">メールアドレスを入力してください</p>
          </div>
        </div>

        <!-- address -->
        <div class="form_items address_form_items">
          <p class="form_item">ご住所<span class="required_tag">※必須</span></p>
          <div class="address_wrapper">
            <div class="address_item">
              <p>郵便番号：</p>
              <div class="address_input post_number">
                <input
                type="text"
                v-model="userData.post00"
                @change="setZip"
                required
                >ー
                <input
                type="text"
                v-model="userData.post01"
                @change="setZip"
                required
                >
              </div>
            </div>
            <div class="address_item">
              <p>都道府県：</p>
              <div class="address_input">
                <select v-model="pref_id" required>
                  <option
                    v-for="pref in prefectures"
                    :key="pref.code"
                    :value="pref.code"
                  >
                    {{ pref.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="address_item">
              <p>市区町村：</p>
              <div class="address_input">
                <input
                  type="text"
                  v-model="userData.street"
                  required
                >
              </div>
            </div>
            <div class="address_item">
              <p>番地：</p>
              <div class="address_input">
                <input
                  type="text"
                  v-model="userData.address"
                  required
                >
              </div>
            </div>
            <div class="address_item">
              <p>建物：</p>
              <div class="address_input">
                <input
                  type="text"
                  v-model="userData.building"
                >
              </div>
            </div>
          </div>
        </div>

        <button type="submit"><img src="../src/assets/confirm_btn.png" alt="次へ進む"></button>

      </form>
    </div>

    <!-- confirm -->
    <div id="confirm" v-show="showConfirm">
      <Confirm :userData="userData"></Confirm>
      <div class="confirm_buttons">
        <button @click="onClickSendButton"><img src="../src/assets/btn.png" alt="送信ボタン"></button>
        <a @click="onClickFix">修正する</a>
      </div>
    </div>

    <!-- footer -->
    <Footer></Footer>

  </div>
</template>

<script>
import {db} from '../src/plugins/firebase';
import prefectures from '../public/prefectures.json';
import Titlebar from '../src/components/Titlebar';
import MainImage from '../src/components/MainImage';
import Footer from '../src/components/Footer';
import Confirm from './Confirm.vue';
let YubinBango = require('yubinbango-core2')


export default {
  components: { Titlebar, MainImage, Footer, Confirm },
  data() {
    return {
      showConfirm: false,
      userData: {
      title: "giftbook_202011_1",
      sei: "",
      mei: "",
      seiKana: "",
      meiKana: "",
      birthYear: "1990",
      birthMonth: "",
      birthDay: "",
      gender: "",
      tel00: "",
      tel01: "",
      tel02: "",
      mail: "",
      post00: "",
      post01: "",
      pref: "",
      street: "",
      address: "",
      building: "",
      createdDate: "",
      prefId: "",
      registered: "false"
      },
      // 郵便番号検索
      zip: null,
      pref_id: null,
      pref:{},
      prefectures: {},
      yearList: [],
      monthList: [],
      dayList: [],
      telError: false,
      mailError: false
    }
  },
  methods: {
    setZip() {
      this.zip = this.userData.post00 + this.userData.post01
    },
    onClickFormButton() {
      // メールアドレスのバリデーション
      if (!this.userData.mail.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
        this.mailError = true;
      }

      // 電話番号のバリデーション
      const telNumber = this.userData.tel00 + this.userData.tel01 + this.userData.tel02
      if (!telNumber.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
        this.telError = true;
      }

      // 都道府県コードを都道府県に反映
      const code = this.pref_id - 1
      this.userData.pref = this.prefectures[code].name

      this.showConfirm = !this.showConfirm

      window.location.hash = "confirm"
    },
    onClickFix() {
      this.showConfirm = !this.showConfirm
    },
    onClickSendButton() {
      this.userData.createdDate = new Date().toISOString();
      this.userData.prefId = this.pref_id;
      db.collection('user').add(this.userData)
      this.$router.push('/thanks')
    }
  },
  created() {
    this.prefectures = prefectures.data

    // 誕生年
    for(let i = 1900; i <= 2020; i++){
      this.yearList.push(i)
    }
    // 誕生月
    for(let i = 1; i <= 12; i++){
      this.monthList.push(i)
    }
    // 誕生日
    for(let i = 1; i <= 31; i++){
      this.dayList.push(i)
    }
  },
  watch: {
    zip: function(zip) {
      let _this = this;
      new YubinBango.Core(zip, function(addr) {
        _this.pref_id  = addr.region_id // 都道府県ID
        _this.userData.street = addr.locality + addr.street  // 市区町村
      })
    }
  }
}
</script>

<style scoped>
.main_image img {
  width: 100%;
}

h1 {
  margin: 0 auto 60px;
  letter-spacing: 2px;
  padding-top: 30px;
}
h2 {
  margin-bottom: 10px;
}
#form {
  width: 50%;
  margin: 0 auto;
  padding: 50px 0;
}
.form_items {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 10px;
}
.form_item {
  width: 30%;
  height: 51px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 2px;
  background-color: #C73A33;
  color: #fff;
  display: inline-block;
  padding: 10px 10px;
  margin-right: 50px;
  border-radius: 50px;
}
.input_wrapper {
  display: flex;
  width: 60%;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 2px;
  justify-content: space-between;
}
.input_wrapper input {
  margin-right: 20px;
  background-color: #fff;
}
input {
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 20px;
  width: 100%;
  border: 2px solid #e5e5e5;
  background-color: #fff;
}
select {
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 20px;
  width: 50%;
  border: 2px solid #e5e5e5;
  display: block;
  background-color: #fff;
}
.required_tag {
  font-size: 14px;
}
button {
  margin-top: 60px;
  border: none;
  background: none;
  cursor: pointer;
}
.logo:hover,
button:hover {
  opacity: 0.8;
}
button img {
  width: 280px;
}
.error_message {
  color: #C73A33;
  margin: 2px 0 0 5px;
  font-size: 15px;
}

/* birthDay */
#birth input {
  width: 30%;
}
.birth_select {
  display: flex;
  align-items: center;
}
.year_select {
  width: 28%;
}
.month_select, .day_select {
  width: 20%;
}
.birth_text {
  font-weight: bold;
  font-size: 18px;
  margin-right: 20px;
}

/* gender */
.gender_wrapper {
  width: 25%;
}
#men, #women {
  display: none;
}
.gender_label {
  border: 2px solid #e5e5e5;
  padding: 10px 30px;
  border-radius: 30px;
  margin-right: 30px;
  color: lightgrey;
  background: #fff;
}
.gender_label:hover {
  cursor: pointer;
  opacity: 0.8;
}
.select_men {
  background: lightgrey;
  border: 2px solid #333;
  color: #333;
}

/* tel */
.tel_wrapper {
  width: 60%;
  flex-direction: column;
  align-items: flex-start;
}
.tel_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tel_items input {
  margin-right: 0;
  width: 25%;
}

/* mail */
.mail_wrapper {
  flex-direction: column;
  align-items: flex-start;
}

/* address */
.address_form_items {
  align-items: flex-start;
}
.address_wrapper {
  width: 65%;
}
.address_item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.address_item p {
  width: 30%;
  text-align: left;
  font-weight: bold;
}
.address_input {
  width: 100%;
}
.address_item input, select {
  text-align: left;
  font-weight: bold;
}
.post_number {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.post_number input {
  width: 30%;
}
.confirm_buttons {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: underline;
}
.confirm_buttons:hover {
  opacity: 0.8;
}

@media screen and (max-width: 960px) {
  .title_wrapper {
    width: 95%;
    margin: 0 auto;
  }
  h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }
  h2 {
    font-size: 18px;
  }
  #form {
    width: 95%;
  }
  .form_items {
    flex-direction: column;
  }
  .form_item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input_wrapper {
    width: 100%;
  }
  .input_wrapper input {
    width: 48%;
    margin-right: 0;
  }
  .gender_wrapper {
    width: 60%;
    margin: 0 auto;
  }
  .gender_label {
    margin-right: 0;
  }
  .tel_items {
    width: 90%;
    margin: 0 auto;
  }
  .mail_wrapper input {
    width: 100%;
  }
  .address_wrapper {
    width: 100%;
  }
  button {
    margin-top: 0;
  }
  .post_number input {
    width: 38%;
  }
}

</style>
