<template>

  <div id="main_wrapper">
    <h2>内容をご確認の上送信して下さい。</h2>
    <div class="confirm_wrapper">
      <p class="confirm_item">
        氏名:
      </p>
      <div class="confirm_data">
        {{ userData.sei }}
        {{ userData.mei }}
        <p>様</p>
      </div>
    </div>
    <div class="confirm_wrapper">
      <p class="confirm_item">
        フリガナ:
      </p>
      <div class="confirm_data">
        {{ userData.seiKana }}
        {{ userData.meiKana }}
        <p>様</p>
      </div>
    </div>
    <div class="confirm_wrapper">
      <p class="confirm_item">
        生年月日:
      </p>
      <div class="confirm_data">
        {{ userData.birthYear }}年
        {{ userData.birthMonth }}月
        {{ userData.birthDay }}日
      </div>
    </div>
    <div class="confirm_wrapper">
      <p class="confirm_item">
        性別:
      </p>
      <div class="confirm_data">
        {{ userData.gender }}
      </div>
    </div>
    <div class="confirm_wrapper">
      <p class="confirm_item">
        電話番号:
      </p>
      <div class="confirm_data">
        {{ userData.tel00 }}
        <p>-</p>
        {{ userData.tel01 }}
        <p>-</p>
        {{ userData.tel02 }}
      </div>
    </div>
    <div class="confirm_wrapper">
      <p class="confirm_item">
        メールアドレス:
      </p>
      <div class="confirm_data">
        {{ userData.mail }}
      </div>
    </div>
    <div class="confirm_wrapper">
      <p class="confirm_item">
        郵便番号:
      </p>
      <div class="confirm_data">
        {{ userData.post00 }}
        <p>-</p>
        {{ userData.post01 }}
      </div>
    </div>
    <div class="confirm_wrapper">
      <p class="confirm_item">
        ご住所:
      </p>
      <div class="confirm_data">
        {{ userData.pref }}
        {{ userData.street }}
        {{ userData.address }}
        {{ userData.building }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:[
      "userData"
    ]
}
</script>

<style scoped>
h2 {
  margin:20px auto 80px;
}
.confirm_wrapper {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0 auto 30px;
  border-bottom: 2px solid #ccc;
}
.confirm_item {
  font-size: 18px;
  font-weight: bold;
  width: 30%;
  text-align: left;
}
.confirm_data {
  display: flex;
  font-size: 20px;
  font-weight: bold;
}
.confirm_data p {
  margin: 0 2px;
}

@media screen and (max-width: 960px) {
  .h2 {
    margin: 20px auto 30px;
  }
  .confirm_wrapper {
    width: 90%;
  }
}

</style>