<template>
  <div id="main_wrapper">

    <!-- header -->
    <Titlebar></Titlebar>

    <div class="management_wrapper">
      <div class="logout_wrapper" v-if="name.length" href="javascript:void(0);">
        <a class="logout_button" @click.prevent="signOut">ログアウト</a>
      </div>

      <div class="table_wrapper" v-if="name.length">
      <table>
          <thead>
              <tr>
                  <th>日時</th>
                  <th>キャンペーン名</th>
                  <th>姓</th>
                  <th>名</th>
                  <th>セイ</th>
                  <th>メイ</th>
                  <th>生年</th>
                  <th>月</th>
                  <th>日</th>
                  <th>性別</th>
                  <th>電話番号</th>
                  <th>電話番号</th>
                  <th>電話番号</th>
                  <th>メールアドレス</th>
                  <th>郵便番号</th>
                  <th>郵便番号</th>
                  <th>都道府県</th>
                  <th>市区町村</th>
                  <th>番地</th>
                  <th>建物</th>
              </tr>
          </thead>
          <tbody id="js-tbody">
              <tr v-for="list in lists" :key="list.id">
                  <td>{{ list.createdDate | dateFormat}}</td>
                  <td>{{ list.title}}</td>
                  <td>{{ list.sei }}</td>
                  <td>{{ list.mei }}</td>
                  <td>{{ list.seiKana }}</td>
                  <td>{{ list.meiKana }}</td>
                  <td>{{ list.birthYear }}</td>
                  <td>{{ list.birthMonth }}</td>
                  <td>{{ list.birthDay }}</td>
                  <td>{{ list.gender }}</td>
                  <td>{{ list.tel00 }}</td>
                  <td>{{ list.tel01 }}</td>
                  <td>{{ list.tel02 }}</td>
                  <td>{{ list.mail }}</td>
                  <td>{{ list.post00 }}</td>
                  <td>{{ list.post01}}</td>
                  <td>{{ list.pref }}</td>
                  <td>{{ list.street }}</td>
                  <td>{{ list.address }}</td>
                  <td>{{ list.building }}</td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import {db} from '../src/plugins/firebase';
import Titlebar from '../src/components/Titlebar';
import firebase from 'firebase'

export default {
  components: { Titlebar },
  data() {
    return {
      lists: {},
      name: firebase.auth().currentUser.email
    }
  },
  firestore() {
    return {
      lists: db.collection('user')
    }
  },
  methods: {
    async handleSignIn () {
      try {
        const googleUser = await this.$gAuth.signIn()
        if (!googleUser) {
          return null
        }
        this.$store.commit('setUserInfo', googleUser.getAuthResponse())
      } catch (error) {
        console.error(error)
        return null
      }
    },
    async handleSignOut () {
      try {
        await this.$gAuth.signOut()
        this.$store.commit('setUserInfo', {})
        window.location.href = '/management'
      } catch (error) {
        console.error(error)
      }
    },

    signOut: function () {
      firebase.auth().signOut().then(() => {
        this.$router.push('/signin')
      })
    }
  },
  filters: {
    dateFormat: function(value){

      var a = new Date(value);
      var b = a.toLocaleString("ja-JP", {timeZone: "Asia/Tokyo", year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"});
      /*
// Mon Mar 09 2020 19:21:13 GMT+0900 (日本標準時)
a.toLocaleDateString("ja-JP", {timeZone: "Asia/Tokyo"})
// "2020/3/9"
a.toLocaleString("ja-JP", {timeZone: "Asia/Tokyo"})
// "2020/3/9 19:21:13"
a.toLocaleString("ja-JP", {timeZone: "America/New_York"})
// "2020/3/9 6:21:13"
*/

      return b;
    }
  }

}
</script>

<style scoped>
.management_wrapper {
  height: 68vh;
}
.login_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72vh;
}

.logout_wrapper {
  margin: 30px auto 0;
  display: block;
  text-align: right;
  width: 90%;
}

.login_button, .logout_button {
  display: inline-block;
  padding: 10px 30px;
  background: #9CC739;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 18px;
  cursor: pointer;
}

.login_button:hover, .logout_button:hover {
  opacity: 0.8;
}

.logout_button {
  background: #E73731;
  padding: 5px 20px;
  font-size: 16px;
}

.table_wrapper {
  width: 90%;
  height: 73vh;
  margin: 30px auto 0;
  overflow: scroll;
}
table {
  border-collapse: collapse;
  border: 2px solid #333;
}
thead {
  background: #333;
  color: #fff;
}
table th {
  border-right: 1px solid #fff;
  padding: 5px 10px;
  white-space: nowrap;
}
table td {
  border: solid 1px #333;
  padding: 5px 10px;
  white-space: nowrap;
}
</style>