import Vue from 'vue'
import Router from 'vue-router'
import Lp from '../views/Lp'
import Form from '../views/Form'
import Thanks from '../views/Thanks'
import Management from '../views/Management'
import Signup from '../views/Signup'
import Signin from '../views/Signin'
import SigninG from '../views/SigninG'
import firebase from 'firebase'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'lp',
      component: Lp
    },
    {
      path: '/form',
      name: 'form',
      component: Form
    },
    {
      path: '/thanks',
      name: 'thanks',
      component: Thanks,
    },
    {
      path: '/management',
      name: 'management',
      component: Management,
      meta: { requiresAuth: true }
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/signin',
      name: 'Signin',
      component: Signin
    },
    {
      path: '/signinG',
      name: 'SigninG',
      component: SigninG
    }

  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        next()
      } else {
        next({
          path: '/signinG',
          query: { redirect: to.fullPath }
        })
      }
    })
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
})

export default router